import React from "react"
import PropTypes from "prop-types"
import "./Logo.css"

const LogoHoriztonal = props => {
  return (
    <svg
      className="Logo"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1000 248.84"
    >
      <title>Type Brigade</title>
      <g fill={props.fill}>
        <g className="Logo-mark">
          <path d="M0,53.08c0,13.28,3.73,17,17,17H63.45l56.82,116.12V70.09h46.45c13.27,0,17.83-3.73,17.83-17V17.42C184.55,4.15,180,0,166.72,0H17C3.73,0,0,4.15,0,17.42Z" />
          <path d="M250.39,173.08v-.43c11.22-4.53,16.06-14.93,16.06-28.74h0c0-26.74-20.58-35.25-50.56-35.25H161.33V248.84h54.51c30.41,0,57.85-11.43,57.85-38.17v-3.4C273.69,192.17,263.33,176.53,250.39,173.08Zm-35.56,47.56H194.92V185.8h19.91c15.76,0,24.88,5.39,24.88,17.42S230.59,220.64,214.83,220.64Z" />
        </g>
        <g className="Logo-name">
          <polygon points="372.53 108.65 372.53 121.35 393.71 121.35 393.71 185.63 407.5 185.63 407.5 121.35 428.67 121.35 428.67 108.65 372.53 108.65" />
          <path d="M468,131.57l-14.85,38L438.8,131.76l-.07-.19H424.67l21.51,53L443.93,190c-1.21,3-2.58,5.1-4.06,6.27a8.64,8.64,0,0,1-5.49,1.73,13.76,13.76,0,0,1-5.45-1.06l-.41-.18v11.45l.18.07a26.32,26.32,0,0,0,3.15,1.05,17,17,0,0,0,4.39.5,17.23,17.23,0,0,0,11.7-4.22c3.19-2.78,6.13-7.41,8.76-13.75l24.94-60.32Z" />
          <path d="M543.08,147.36a28.65,28.65,0,0,0-5.57-9,25.69,25.69,0,0,0-8.33-6,25.33,25.33,0,0,0-10.44-2.15,23.31,23.31,0,0,0-10.5,2.43,21.22,21.22,0,0,0-7.32,5.93v-7.05H487.78v76.76h13.14V178.91a19.8,19.8,0,0,0,7.33,5.77,25.62,25.62,0,0,0,20.93,0,26.24,26.24,0,0,0,8.33-6,28.46,28.46,0,0,0,5.57-9,30.32,30.32,0,0,0,2-11.13A30.63,30.63,0,0,0,543.08,147.36Zm-27-5.05a15.17,15.17,0,0,1,6.21,1.28,15.35,15.35,0,0,1,5,3.48,16.85,16.85,0,0,1,3.33,5.15,17.16,17.16,0,0,1,0,12.76,16.85,16.85,0,0,1-3.33,5.15,15.54,15.54,0,0,1-17.4,3.48,15.46,15.46,0,0,1-5-3.48,16.64,16.64,0,0,1-3.32-5.15,17,17,0,0,1,0-12.76,16.64,16.64,0,0,1,3.32-5.15,15.62,15.62,0,0,1,5-3.48A15.24,15.24,0,0,1,516.12,142.31Z" />
          <path d="M604.2,147.1a26.16,26.16,0,0,0-5.24-8.89,24.33,24.33,0,0,0-8.18-5.85,26.39,26.39,0,0,0-10.7-2.1,27.33,27.33,0,0,0-10.93,2.15,26.66,26.66,0,0,0-8.6,5.91,26.06,26.06,0,0,0-5.63,8.94,31.23,31.23,0,0,0-2,11.23,30.71,30.71,0,0,0,2,11.35,26.26,26.26,0,0,0,5.84,9,27.17,27.17,0,0,0,9.16,5.95A32.19,32.19,0,0,0,582,186.94a40.12,40.12,0,0,0,11.32-1.54,37.07,37.07,0,0,0,9.47-4.18l.13-.08V168.06l-.47.35c-6.32,4.74-12.91,7.14-19.58,7.14-4.92,0-8.87-1.28-11.75-3.8a14.72,14.72,0,0,1-5-8.93h39.52l.05-.24a17.88,17.88,0,0,0,.27-2.16c0-.62.06-1.38.06-2.26A32.9,32.9,0,0,0,604.2,147.1Zm-29.52-5a13.29,13.29,0,0,1,5.18-1,12.84,12.84,0,0,1,9.13,3.36,13.29,13.29,0,0,1,4.07,8.39H566.12a13.55,13.55,0,0,1,1.39-4.15,14.76,14.76,0,0,1,2.94-3.85A14,14,0,0,1,574.68,142.12Z" />
          <path d="M688,151.44a19.87,19.87,0,0,0-9.64-6.87,16.14,16.14,0,0,0,6.22-5.9,18,18,0,0,0,2.39-9.32,20.51,20.51,0,0,0-1.78-8.69,18.07,18.07,0,0,0-5-6.49,23.81,23.81,0,0,0-7.74-4.09,31.9,31.9,0,0,0-9.75-1.43H636.53v77h28.13a37.2,37.2,0,0,0,11.11-1.54,25.21,25.21,0,0,0,8.5-4.42,19.18,19.18,0,0,0,5.43-7,22.5,22.5,0,0,0,1.88-9.35A19.67,19.67,0,0,0,688,151.44Zm-17.66-13.86a13.45,13.45,0,0,1-8.55,2.61H650V120.37h11.82a13.61,13.61,0,0,1,8.55,2.56,8.71,8.71,0,0,1,3.27,7.3A8.86,8.86,0,0,1,670.37,137.58Zm-20.37,14h14c4.14,0,7.51,1,10,2.94a9.76,9.76,0,0,1,3.76,8.12,9.87,9.87,0,0,1-3.71,8.28c-2.48,1.91-5.83,2.89-10,2.89H650Z" />
          <path d="M731.87,130.74a5.77,5.77,0,0,0-2.38-.26,17.79,17.79,0,0,0-9.77,2.83,16.47,16.47,0,0,0-5.88,6.39v-8.13h-13v54.06H714V161.77c0-5.44,1.32-9.84,3.92-13.08a13.14,13.14,0,0,1,10.74-4.86,9.07,9.07,0,0,1,2.86.31,3.35,3.35,0,0,1,.71.31V131A1,1,0,0,0,731.87,130.74Z" />
          <g>
            <path d="M749.59,124.3a8,8,0,0,0,8.15-8,8.09,8.09,0,0,0-2.31-5.72,8.06,8.06,0,0,0-11.5-.06,7.9,7.9,0,0,0-2.38,5.78,8.13,8.13,0,0,0,8,8Z" />
            <rect x="743.08" y="131.57" width="13.25" height="54.06" />
          </g>
          <path d="M812.57,180c-3.49-2.47-8.23-3.72-14.08-3.72H783.32a9.36,9.36,0,0,1-4.13-.78,2.76,2.76,0,0,1-1.47-2.74,3.7,3.7,0,0,1,.76-2.18,5.06,5.06,0,0,1,2.64-1.66,24.26,24.26,0,0,0,9.07,1.62,24.75,24.75,0,0,0,8.4-1.43,21.4,21.4,0,0,0,7-4.1,20.29,20.29,0,0,0,4.76-6.31,18.62,18.62,0,0,0,1.77-8.21,20.23,20.23,0,0,0-.6-4.93,21.57,21.57,0,0,0-1.5-4.05h6.8v-10h-18.7c-1.22-.36-2.5-.67-3.8-.92a21.35,21.35,0,0,0-4.1-.39,24.08,24.08,0,0,0-8.5,1.49,22.13,22.13,0,0,0-7,4.15,19.23,19.23,0,0,0-4.71,6.37,20.56,20.56,0,0,0-.28,15.79,18.5,18.5,0,0,0,3.74,5.73,20.06,20.06,0,0,0-4.51,4.07,10.79,10.79,0,0,0-2.2,7.11,11.75,11.75,0,0,0,1.12,5,11.22,11.22,0,0,0,3.14,4,16.21,16.21,0,0,0-4.53,4.44,11.35,11.35,0,0,0-2,6.6,12.47,12.47,0,0,0,1.79,6.76,14,14,0,0,0,5,4.69,24.79,24.79,0,0,0,7.85,2.65,58.14,58.14,0,0,0,10.2.82,51.94,51.94,0,0,0,10.76-1.1,30.35,30.35,0,0,0,9.09-3.41,19.39,19.39,0,0,0,6.31-5.88,14.88,14.88,0,0,0,2.4-8.46C817.88,186.21,816.09,182.51,812.57,180Zm-6.52,12.19c0,2.51-1.42,4.39-4.36,5.73S794.63,200,789.54,200a25,25,0,0,1-9.87-1.62,5.27,5.27,0,0,1-3.59-5.18c0-2.43,1.39-4.34,4.25-5.82h16a18.91,18.91,0,0,1,7.36,1.12A3.73,3.73,0,0,1,806.05,192.22Zm-12-32.75a11,11,0,0,1-7.65,0,9,9,0,0,1-3.12-1.95,9.64,9.64,0,0,1-2.91-7,9.22,9.22,0,0,1,.79-3.86,9.85,9.85,0,0,1,2.12-3,10.13,10.13,0,0,1,13.89,0,9.85,9.85,0,0,1,2.12,3,9.38,9.38,0,0,1,.79,3.86,9.66,9.66,0,0,1-.8,3.93,9.54,9.54,0,0,1-2.11,3.07A9.21,9.21,0,0,1,794,159.47Z" />
          <path d="M863.27,135.74c-4-3.64-9.8-5.48-17.34-5.48A31.55,31.55,0,0,0,835.14,132a28.87,28.87,0,0,0-8.38,4.63l-.11.09v13.14l.49-.45a32,32,0,0,1,8.27-5.51,23,23,0,0,1,9.65-2c3.55,0,6.36.75,8.34,2.23a7.45,7.45,0,0,1,2.93,6.32V151l-13.61,2.14c-12.69,2-19.13,8.06-19.13,18.08a16,16,0,0,0,1.23,6.34,14.11,14.11,0,0,0,3.45,5,16.07,16.07,0,0,0,5.34,3.23,19.23,19.23,0,0,0,6.76,1.16,23,23,0,0,0,9.56-2,17.71,17.71,0,0,0,6.86-5.54l.1,6.27h12.36V151C869.25,144.5,867.24,139.38,863.27,135.74Zm-6.83,25.61V164a11.54,11.54,0,0,1-3.61,8.74,13.25,13.25,0,0,1-9.41,3.41,7.69,7.69,0,0,1-5-1.58,5.4,5.4,0,0,1-2-4.25c0-3.72,2.78-6,8.49-7Z" />
          <path d="M923.56,108.65v29.64a19.82,19.82,0,0,0-7.33-5.77,25.62,25.62,0,0,0-20.93-.05,26.33,26.33,0,0,0-8.33,6,28.65,28.65,0,0,0-5.57,9,30.32,30.32,0,0,0-2,11.13,30.63,30.63,0,0,0,2,11.24,28.46,28.46,0,0,0,5.57,9,25.62,25.62,0,0,0,8.34,6,25.32,25.32,0,0,0,10.43,2.15,23.34,23.34,0,0,0,10.5-2.43,21,21,0,0,0,7.32-5.92v7H936.7v-77Zm-15.2,66.25a15.43,15.43,0,0,1-11.19-4.77,16.85,16.85,0,0,1-3.33-5.15,17.16,17.16,0,0,1,0-12.76,17,17,0,0,1,3.33-5.15,15.49,15.49,0,0,1,22.38,0,16.85,16.85,0,0,1,3.33,5.15,17.16,17.16,0,0,1,0,12.76,16.85,16.85,0,0,1-3.33,5.15,15.43,15.43,0,0,1-11.19,4.77Z" />
          <path d="M998.18,147.1a26,26,0,0,0-5.24-8.89,24.33,24.33,0,0,0-8.18-5.85,26.36,26.36,0,0,0-10.7-2.1,27.25,27.25,0,0,0-10.92,2.15,26.6,26.6,0,0,0-8.61,5.91,26.06,26.06,0,0,0-5.63,8.94,31.23,31.23,0,0,0-2,11.23,30.71,30.71,0,0,0,2,11.35,26.14,26.14,0,0,0,5.85,9,27.13,27.13,0,0,0,9.15,5.95A32.19,32.19,0,0,0,976,186.94a40.24,40.24,0,0,0,11.33-1.54,37,37,0,0,0,9.46-4.18l.13-.08V168.06l-.47.35c-6.32,4.74-12.9,7.14-19.57,7.14-4.93,0-8.88-1.28-11.76-3.8a14.72,14.72,0,0,1-5-8.93h39.52l0-.24a17.88,17.88,0,0,0,.27-2.16c0-.62.06-1.38.06-2.26A32.9,32.9,0,0,0,998.18,147.1Zm-29.52-5a13.33,13.33,0,0,1,5.18-1,12.84,12.84,0,0,1,9.13,3.36,13.35,13.35,0,0,1,4.08,8.39H960.1a13.57,13.57,0,0,1,1.4-4.15,14.34,14.34,0,0,1,2.94-3.85A13.87,13.87,0,0,1,968.66,142.12Z" />
        </g>
      </g>
    </svg>
  )
}

LogoHoriztonal.propTypes = {
  fill: PropTypes.oneOf(["black", "white"]),
}

LogoHoriztonal.defaultProps = {
  fill: "white",
}

export default LogoHoriztonal
