// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-jsx": () => import("./../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-events-index-jsx": () => import("./../src/pages/events/index.jsx" /* webpackChunkName: "component---src-pages-events-index-jsx" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-50-md": () => import("./../src/pages/50.md" /* webpackChunkName: "component---src-pages-50-md" */),
  "component---src-pages-about-md": () => import("./../src/pages/about.md" /* webpackChunkName: "component---src-pages-about-md" */),
  "component---src-pages-blog-announcing-the-type-walk-md": () => import("./../src/pages/blog/announcing-the-type-walk.md" /* webpackChunkName: "component---src-pages-blog-announcing-the-type-walk-md" */),
  "component---src-pages-blog-announcing-type-brigade-26-md": () => import("./../src/pages/blog/announcing-type-brigade-26.md" /* webpackChunkName: "component---src-pages-blog-announcing-type-brigade-26-md" */),
  "component---src-pages-blog-bruno-maag-at-type-brigade-md": () => import("./../src/pages/blog/bruno-maag-at-type-brigade.md" /* webpackChunkName: "component---src-pages-blog-bruno-maag-at-type-brigade-md" */),
  "component---src-pages-blog-commercial-type-at-type-brigade-md": () => import("./../src/pages/blog/commercial-type-at-type-brigade.md" /* webpackChunkName: "component---src-pages-blog-commercial-type-at-type-brigade-md" */),
  "component---src-pages-blog-introducing-type-brigade-md": () => import("./../src/pages/blog/introducing-type-brigade.md" /* webpackChunkName: "component---src-pages-blog-introducing-type-brigade-md" */),
  "component---src-pages-blog-our-number-twenty-md": () => import("./../src/pages/blog/our-number-twenty.md" /* webpackChunkName: "component---src-pages-blog-our-number-twenty-md" */),
  "component---src-pages-blog-the-call-for-speakers-is-open-md": () => import("./../src/pages/blog/the-call-for-speakers-is-open.md" /* webpackChunkName: "component---src-pages-blog-the-call-for-speakers-is-open-md" */),
  "component---src-pages-blog-type-brigade-26-has-moved-md": () => import("./../src/pages/blog/type-brigade-26-has-moved.md" /* webpackChunkName: "component---src-pages-blog-type-brigade-26-has-moved-md" */),
  "component---src-pages-blog-type-brigade-43-follow-up-md": () => import("./../src/pages/blog/type-brigade-43-follow-up.md" /* webpackChunkName: "component---src-pages-blog-type-brigade-43-follow-up-md" */),
  "component---src-pages-blog-type-brigade-50-cancelled-md": () => import("./../src/pages/blog/type-brigade-50-cancelled.md" /* webpackChunkName: "component---src-pages-blog-type-brigade-50-cancelled-md" */),
  "component---src-pages-code-of-conduct-md": () => import("./../src/pages/code-of-conduct.md" /* webpackChunkName: "component---src-pages-code-of-conduct-md" */),
  "component---src-pages-events-20-md": () => import("./../src/pages/events/20.md" /* webpackChunkName: "component---src-pages-events-20-md" */),
  "component---src-pages-events-21-md": () => import("./../src/pages/events/21.md" /* webpackChunkName: "component---src-pages-events-21-md" */),
  "component---src-pages-events-24-md": () => import("./../src/pages/events/24.md" /* webpackChunkName: "component---src-pages-events-24-md" */),
  "component---src-pages-sponsors-md": () => import("./../src/pages/sponsors.md" /* webpackChunkName: "component---src-pages-sponsors-md" */),
  "component---src-pages-call-for-speakers-md": () => import("./../src/pages/call-for-speakers.md" /* webpackChunkName: "component---src-pages-call-for-speakers-md" */),
  "component---src-pages-details-for-speakers-md": () => import("./../src/pages/details-for-speakers.md" /* webpackChunkName: "component---src-pages-details-for-speakers-md" */)
}

